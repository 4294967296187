// src/App.tsx
import React from 'react';


import MeterReadingsChart from "./MeterReadingsChart";
import {BrowserRouter, Route, Routes} from "react-router-dom";


const App: React.FC = () => {





  return (

    <div className="App">
        <div className="header">
          <div><img className="navbar-brand" src="https://kwiqly.com/favicon.png"/><span className={'title'}>kInsight2</span> <span className="navbar-version">0.0.2</span></div>
        </div>
        <div className="content">
          <BrowserRouter>
            <Routes>
              <Route path="/meterreadings/:meterKey" element={
                  <MeterReadingsChart />
              } />



              {/* Other routes can also be defined here */}
            </Routes>
          </BrowserRouter>
        </div>
        <div className="footer">
          © 2024 kWIQly GmbH
        </div>
    </div>
  );


}

export default App;

